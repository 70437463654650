
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.v-input-content-editor::v-deep {
    > .v-input__control {
        > .v-input__slot {
            padding: 0 !important;

            > .v-text-field__slot {
                max-width: 100%;

                > .v-label {
                    top: 0px;
                    left: 0px;
                    right: auto;
                    position: absolute;
                    padding-left: 16px;
                }

                > .content-editor {
                    background-color: transparent;
                    margin-top: 26px;
                    width: 100%;

                    .ql-editor {
                        border-bottom: none !important;
                        padding: 12px !important;
                        font-family: 'DM Sans', sans-serif !important;
                        font-size: 16px !important;

                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }

        [role='progressbar'] {
            position: relative;
            top: -9px;
            margin-bottom: -2px;
        }
    }

    &.v-text-field--filled
        > .v-input__control
        > .v-input__slot
        > .v-text-field__slot
        > .v-label {
        top: 13px;

        &.v-label--active {
            top: 18px;
        }
    }
}
