
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.outline-input {
    &.outline-input--parent {
        font-weight: bold !important;
    }
    &:focus,
    &:hover,
    &:active,
    &.v-input--is-focused {
        .outline-action {
            opacity: 1;
        }
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(128, 128, 128, 0.1) !important;
            pointer-events: none;
        }
    }
}

.outline-action {
    opacity: 0;
    margin: auto 0.5em;
    &:focus,
    &:hover,
    &:active {
        opacity: 1;
    }
}
