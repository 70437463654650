
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.custom-quill-link {
    background-color: $blueish;
    padding: 8px;
    padding-bottom: 0;
    border-radius: 5px;
    min-width: 380px !important;
}
